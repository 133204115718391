import type { AppProps } from 'next/app';

/* CSS */
import 'styles/global.css';
import 'styles/hero-mask.css';
import 'styles/print.css';
import useWebvitals from '../hooks/useWebvitals';
import { GoogleTagManager } from '@next/third-parties/google';
import { Environment } from '../config/environment';
import Fonts from '../components/Fonts';

export default function MyApp({ Component, pageProps }: AppProps) {
  useWebvitals();
  const { NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID } = Environment.getBuildtimeEnvVars();

  return (
    <>
      {NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID && (
        <GoogleTagManager gtmId={NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID as string} />
      )}
      <Fonts locale={pageProps.locale} />
      <Component {...pageProps} />
    </>
  );
}

import {
  AugustScript,
  MomentsDisplay,
  MomentsDisplayBG,
  MomentsText,
  MomentsTextBG,
  MomentsDisplayTR,
  MomentsTextTR,
} from '../../styles/fonts';

const Fonts = ({ locale }: { locale: string }) => {
  let momentsDisplay = MomentsDisplay;
  let momentsText = MomentsText;

  if (locale === 'bg_BG') {
    momentsDisplay = MomentsDisplayBG;
    momentsText = MomentsTextBG;
  }

  if (locale === 'tr_TR') {
    momentsDisplay = MomentsDisplayTR;
    momentsText = MomentsTextTR;
  }

  return (
    <style jsx global>{`
      :root {
        --font-oetker-moments-text: ${momentsText.style.fontFamily};
        --font-oetker-moments-display: ${momentsDisplay.style.fontFamily};
        --font-oetker-august-script: ${AugustScript.style.fontFamily};
      }
    `}</style>
  );
};

export default Fonts;
